<template>
  <div class="mod-home">
    <div class="page-header">
      <!-- 数据顶部bar -->
      <div class="data-bar bg-white">
        <ul class="bar-list">
          <li class="bar-item">
            <div class="item-title">
              <div class="number text-clamp1">
                {{ dataInfo.countEmployeeNum }}
              </div>
              <div class="label">员工总数</div>
            </div>

            <div class="item-content">
              <div class="item-content-item">
                <div class="label">部门数量</div>
                <div class="number text-clamp1">
                  {{ dataInfo.departmentNum }}
                </div>
              </div>
              <div class="item-content-item">
                <div class="label">班组数量</div>
                <div class="number text-clamp1">{{ dataInfo.teamNum }}</div>
              </div>
              <div class="item-content-item">
                <div class="label">车辆总数</div>
                <div class="number text-clamp1">{{ dataInfo.countCarNum }}</div>
              </div>
            </div>
          </li>

          <li class="bar-item">
            <div class="item-title">
              <div class="number text-clamp1">
                {{ dataInfo.countAccidentNum }}
              </div>
              <div class="label">事故总数</div>
            </div>

            <div class="item-content">
              <div class="item-content-item">
                <div class="label">车辆事故</div>
                <div class="number text-clamp1">
                  {{ dataInfo.carAccidentNum }}
                </div>
              </div>
              <div class="item-content-item">
                <div class="label">人员事故</div>
                <div class="number text-clamp1">
                  {{ dataInfo.peopleAccidentNum }}
                </div>
              </div>
              <div class="item-content-item">
                <div class="label">已处理完</div>
                <div class="number text-clamp1">
                  {{ dataInfo.completeAccidentNum }}
                </div>
              </div>
              <div class="item-content-item">
                <div class="label">未处理完</div>
                <div class="number text-clamp1">
                  {{ dataInfo.notCompleteAccidentNum }}
                </div>
              </div>
            </div>
          </li>

          <li class="bar-item">
            <div class="item-title">
              <div class="number text-clamp1">
                {{ dataInfo.countHiddenNum }}
              </div>
              <div class="label">隐患总数</div>
            </div>

            <div class="item-content">
              <div class="item-content-item">
                <div class="label">已处理完</div>
                <div class="number text-clamp1">
                  {{ dataInfo.completeHiddenNum }}
                </div>
              </div>
              <div class="item-content-item">
                <div class="label">未处理完</div>
                <div class="number text-clamp1">
                  {{ dataInfo.notCompleteHiddenNum }}
                </div>
              </div>
            </div>
          </li>

          <li class="bar-item">
            <div class="item-title">
              <div class="number text-clamp1">
                {{ dataInfo.countDangerNum }}
              </div>
              <div class="label">风险总数</div>
            </div>

            <div
              class="fengxianData"
              ref="fengxianDataDom"
              v-loading="chartLoading"
              element-loading-text="图表渲染中"
              element-loading-background="rgba(0, 0, 0, 0)"
            ></div>
          </li>
        </ul>
      </div>
    </div>

    <div class="page-body">
      <!-- 数据视图 -->
      <div class="data-view">
        <div class="data-view-left bg-white">
          <div class="title">教育培训情况</div>

          <div
            class="education"
            ref="educationDom"
            v-loading="chartLoading"
            element-loading-text="图表渲染中"
          ></div>
        </div>

        <div class="data-view-right bg-white">
          <div class="title">证件数据分析</div>

          <ul class="certificates-list">
            <!-- 行驶证 -->
            <li class="certificates-item">
              <div
                class="xingshi-card"
                ref="xingshiCardDom"
                v-loading="chartLoading"
                element-loading-text="图表渲染中"
              ></div>
            </li>

            <!-- 驾驶证 -->
            <li class="certificates-item">
              <div
                class="jiashi-card"
                ref="jiashiCardDom"
                v-loading="chartLoading"
                element-loading-text="图表渲染中"
              ></div>
            </li>
            <!-- 资格证 -->
            <li class="certificates-item">
              <div
                class="zige-card"
                ref="zigeCardDom"
                v-loading="chartLoading"
                element-loading-text="图表渲染中"
              ></div>
            </li>
          </ul>

          <!-- <ul class="certificates-list">
            <li class="certificates-item">
              行驶证
              驾驶证
              资格证  

              <div class="certificates" ref="certificatesDom"></div>

              <div class="certificates2" ref="certificates2Dom"></div>

              <div class="certificates3" ref="certificates3Dom"></div>
            </li>
          </ul> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      dataBarList: [1, 2, 3, 4],
      education_chart: "", // 教育培训图形实例
      xingshiCard_chart: "", //行驶证图例
      jiashiCard_chart: "", // 驾驶证图例
      zigeCard_chart: "", // 资格证图例
      fengxianData_chart: "", // 风险数据图例

      dataInfo: {
        departmentNum: "", // 部门数量
        teamNum: "", // 班组数量
        countCarNum: "", // Integer
        countEmployeeNum: "", // 员工总数
        jobEmployeeNum: "", // 在职员工数量
        countAccidentNum: "", // 事故总数
        carAccidentNum: "", // 车辆事故数量
        peopleAccidentNum: "", // 人员事故数量
        completeAccidentNum: "", // 已处理完的事故数量
        notCompleteAccidentNum: "", // 未处理完的事故数量
        countHiddenNum: "", // 隐患总数
        completeHiddenNum: "", // 已处理完的隐患数量
        notCompleteHiddenNum: "", // 未处理完的隐患数量
        countDangerNum: "", // 风险总数
        equipmentDangerNum: "", // 设备设施风险总数
        placeDangerNum: "", // 场所风险总数
        taskDangerNum: "", // 作业活动风险总数
        zgzygqNum: "", // 资格证已到期数量
        zgzlgqNum: "", // 资格证临到期数量
        xszygqNum: "", // 行驶证已到期数量
        xszlgqNum: "", // 行驶证临到期数量
        dlyszygqNum: "", // 道路运输证已到期数量
        dlyszlgqNum: "", // 道路运输证临到期数量
      },
      examList: [],

      chartLoading: true,

      weatherInfo: '',  // 天气情况
    };
  },
  created() {
    this.getIndexData();

    this.getWeather();
  },
  mounted() {
    this.resizeHandler();
  },
  methods: {
    // 获取当前城市的天气
    getWeather() {
      axios({
        method: 'get',
        url: 'https://restapi.amap.com/v3/weather/weatherInfo',
        params: {
          key: '6a16103484e1de9631ef89ee1c722f9e',
          city: 110101
        }
      }).then(res => {
        if(res.status == 200) {
          if(res.data.status == 1) {
            this.weatherInfo = res.data.lives[0]
          }
        }
      })
    },

    // 获取首页数据
    getIndexData() {
      this.$http.indexData
        .info()
        .then((res) => {
          if (res.data) {
            this.dataInfo = res.data;
            this.examList = res.data.examList;

            this.xingshiCardInit({
              title: "行驶证",
              xszygqNum: res.data.xszygqNum,
              xszlgqNum: res.data.xszlgqNum,
            });

            this.jiashiCardInit({
              title: "道路运输证",
              dlyszygqNum: res.data.dlyszygqNum,
              dlyszlgqNum: res.data.dlyszlgqNum,
            });

            this.zigeCardInit({
              title: "资格证",
              zgzygqNum: res.data.zgzygqNum,
              zgzlgqNum: res.data.zgzlgqNum,
            });

            this.educationInit(this.examList);
            this.fengxianDataInit({
              equipmentDangerNum: res.data.equipmentDangerNum,
              placeDangerNum: res.data.placeDangerNum,
              taskDangerNum: res.data.taskDangerNum,
            });
          }
        })
        .catch(() => {});
    },
    // 监听窗口变化，重构echart图形
    resizeHandler() {
      window.onresize = () => {
        this.education_chart.resize();
        this.xingshiCard_chart.resize();
        this.zigeCard_chart.resize();
      };
    },
    // 判断数量是否大于指定位数（默认五位）满足条件加上省略号
    judgeNumber(val, maxNum = 5) {
      val = val + "";
      if (val.length > maxNum) {
        val = (val + "").substring(0, maxNum) + "...";
      }
      return val;
    },
    // 教育培训视图初始化
    educationInit(propData) {
      let notPassNum = [];
      let notTestNum = [];
      let passNum = [];
      let testNum = [];

      let dataNum = [];

      let state = propData[0];
      for (let i = 0; i < 10; i++) {
        propData.push(state);
      }

      propData.forEach((ele, idx) => {
        notPassNum.push(ele.notPassNum);
        notTestNum.push(ele.notTestNum);
        passNum.push(ele.passNum);
        testNum.push(ele.testNum);

        dataNum.push("试卷" + (parseInt(idx) + 1));
      });

      this.education_chart = this.$echarts.init(this.$refs.educationDom);

      let option = {
        legend: {
          bottom: 0,
          left: "center",
          icon: "circle",
          itemGap: 33,
          textStyle: {
            fontSize: 12,
            color: "rgba(78, 89, 105, 1)",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "4%",
          top: "26",
        },
        dataZoom: [
          {
            type: "slider",
            // show: true,
            // // 设置组件控制的y轴
            yAxisIndex: 0,
            // 数据窗口范围的起始百分比。范围是：0 ~ 100。表示 0% ~ 100%
            // 也可以用 startValue设置起始值
            startValue: 0,
            endValue: 4,
            width: 12,
            // height: 450,
            // 组件的背景颜色
            // backgroundColor: "rgba(0, 0, 0, 0.4)",
            // 选中范围的填充颜色
            // fillerColor: "#00FFFF",
            // 边框颜色
            borderColor: "#0094E9",
            // 是否显示detail，即拖拽时候显示详细数值信息
            showDetail: false,
            // 控制手柄的尺寸
            handleSize: 16,
            zoomLock: true,
          },
          {
            type: "inside",
            yAxisIndex: [0],

            // 不按任何功能键，鼠标滚轮能触发缩放
            zoomOnMouseWheel: false,
            // 不按任何功能键，鼠标移动能触发数据窗口平移
            moveOnMouseWheel: true,
          },
        ],
        xAxis: {
          type: "value",
          min: 0,
          max: this.dataInfo.jobEmployeeNum * 1.5,
          splitNumber: 20,
          splitLine: {
            lineStyle: {
              color: "rgba(242, 243, 245, 1)",
            },
          },
        },
        yAxis: {
          type: "category",
          axisTick: false,
          data: dataNum,
          inverse: true,
        },

        series: [
          {
            name: "考试人数",
            data: testNum,
            type: "bar",
            stack: "one",
            itemStyle: {
              color: "rgba(35, 110, 255, 1)",
            },
          },
          {
            name: "未考试人数",
            data: notTestNum,
            type: "bar",
            stack: "one",
            itemStyle: {
              color: "rgba(0, 178, 255, 1)",
            },
          },
          {
            name: "未合格人数",
            data: notPassNum,
            type: "bar",
            stack: "two",
            itemStyle: {
              color: "rgba(132, 107, 206, 1)",
            },
          },
          {
            name: "合格人数",
            data: passNum,
            type: "bar",
            stack: "two",
            itemStyle: {
              color: "rgba(134, 223, 108, 1)",
            },
          },
        ],
      };

      option && this.education_chart.setOption(option);
    },

    // 行驶证 (视图初始化)
    xingshiCardInit(propData) {
      let chart_title = propData.title;
      let sum_data = this.judgeNumber(
        propData.xszygqNum + propData.xszlgqNum,
        5
      );

      let series_data = [
        { name: "已过期", value: this.judgeNumber(propData.xszygqNum, 8) },
        { name: "临到期", value: this.judgeNumber(propData.xszlgqNum, 8) },
      ];

      this.xingshiCard_chart = this.$echarts.init(this.$refs.xingshiCardDom);

      let option = {
        tooltip: {
          trigger: "item",
        },
        color: ["rgba(64, 158, 255, 1)", "rgba(33, 204, 255, 1)"],
        legend: {
          left: 240,
          top: "center",
          orient: "vertical",
          itemWidth: 10,
          itemHeight: 10,
          itemGap: -5,
          formatter: (val) => {
            let res = series_data.filter((ele) => {
              return ele.name == val;
            });

            let ret_content = `\r\n\r\n{a|${res[0].name}}\r\n\r\n{b|${res[0].value}}`;
            return ret_content;
          },
          textStyle: {
            padding: [7, 0, 0, 0],
            rich: {
              a: {
                color: "rgba(115, 118, 122, 1)",
                fontSize: 13,
              },
              b: {
                color: "rgba(0, 0, 0, 1)",
                fontSize: 17,
                fontWeight: 550,
              },
            },
          },
        },
        series: [
          {
            type: "pie",
            radius: [50, 74],
            center: ["20", "50%"],
            left: "center",
            top: "center",
            itemStyle: {
              borderRadius: 0,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: true,
              position: "center",
              formatter: (val) => {
                let ret_content = `\r\n{a|${chart_title}}\r\n\r\n{b|${sum_data}}`;
                return ret_content;
              },
              rich: {
                a: {
                  fontSize: 13,
                  fontWeight: 550,
                },
                b: {
                  fontSize: 20,
                  fontWeight: 550,
                },
              },
            },
            labelLine: {
              show: false,
            },
            data: series_data,
          },
        ],
      };

      option && this.xingshiCard_chart.setOption(option);
    },

    // 驾驶证 (视图初始化)
    jiashiCardInit(propData) {
      let chart_title = propData.title;
      let sum_data = this.judgeNumber(
        propData.dlyszygqNum + propData.dlyszlgqNum,
        5
      );

      let series_data = [
        { name: "已过期", value: this.judgeNumber(propData.dlyszygqNum, 8) },
        { name: "临到期", value: this.judgeNumber(propData.dlyszlgqNum, 8) },
      ];

      this.jiashiCard_chart = this.$echarts.init(this.$refs.jiashiCardDom);

      let option = {
        tooltip: {
          trigger: "item",
        },
        color: ["rgba(64, 158, 255, 1)", "rgba(33, 204, 255, 1)"],
        legend: {
          left: 240,
          top: "center",
          orient: "vertical",
          itemWidth: 10,
          itemHeight: 10,
          itemGap: -5,
          formatter: (val) => {
            let res = series_data.filter((ele) => {
              return ele.name == val;
            });
            let ret_content = `\r\n\r\n{a|${res[0].name}}\r\n\r\n{b|${res[0].value}}`;
            return ret_content;
          },
          textStyle: {
            padding: [7, 0, 0, 0],
            rich: {
              a: {
                color: "rgba(115, 118, 122, 1)",
                fontSize: 13,
              },
              b: {
                color: "rgba(0, 0, 0, 1)",
                fontSize: 17,
                fontWeight: 550,
              },
            },
          },
        },
        series: [
          {
            type: "pie",
            radius: [50, 74],
            center: ["20", "50%"],
            left: "center",
            top: "center",
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 0,
              borderColor: "#fff",
              borderWidth: 4,
            },
            label: {
              show: true,
              position: "center",
              formatter: (val) => {
                let ret_content = `\r\n{a|${chart_title}}\r\n\r\n{b|${sum_data}}`;
                return ret_content;
              },
              rich: {
                a: {
                  fontSize: 13,
                  fontWeight: 550,
                },
                b: {
                  fontSize: 20,
                  fontWeight: 550,
                },
              },
            },
            labelLine: {
              show: false,
            },
            data: series_data,
          },
        ],
      };

      option && this.jiashiCard_chart.setOption(option);
    },

    // 资格证 (视图初始化)
    zigeCardInit(propData) {
      let chart_title = propData.title;

      let sum_data = this.judgeNumber(
        propData.zgzygqNum + propData.zgzlgqNum,
        5
      );

      let series_data = [
        { name: "已过期", value: this.judgeNumber(propData.zgzygqNum, 8) },
        { name: "临到期", value: this.judgeNumber(propData.zgzlgqNum, 8) },
      ];

      this.zigeCard_chart = this.$echarts.init(this.$refs.zigeCardDom);

      let option = {
        tooltip: {
          trigger: "item",
        },
        color: ["rgba(64, 158, 255, 1)", "rgba(33, 204, 255, 1)"],
        legend: {
          left: 240,
          top: "center",
          orient: "vertical",
          itemWidth: 10,
          itemHeight: 10,
          itemGap: -5,
          formatter: (val) => {
            let res = series_data.filter((ele) => {
              return ele.name == val;
            });
            let ret_content = `\r\n\r\n{a|${res[0].name}}\r\n\r\n{b|${res[0].value}}`;
            return ret_content;
          },
          textStyle: {
            padding: [7, 0, 0, 0],
            rich: {
              a: {
                color: "rgba(115, 118, 122, 1)",
                fontSize: 13,
              },
              b: {
                color: "rgba(0, 0, 0, 1)",
                fontSize: 17,
                fontWeight: 550,
              },
            },
          },
        },
        series: [
          {
            type: "pie",
            radius: [50, 74],
            center: ["20", "50%"],
            left: "center",
            top: "center",
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 0,
              borderColor: "#fff",
              borderWidth: 4,
            },
            label: {
              show: true,
              position: "center",
              formatter: (val) => {
                let ret_content = `\r\n{a|${chart_title}}\r\n\r\n{b|${sum_data}}`;
                return ret_content;
              },
              rich: {
                a: {
                  fontSize: 13,
                  fontWeight: 550,
                },
                b: {
                  fontSize: 20,
                  fontWeight: 550,
                },
              },
            },
            labelLine: {
              show: false,
            },
            data: series_data,
          },
        ],
      };

      option && this.zigeCard_chart.setOption(option);
    },

    // 风险数据 (视图初始化)
    fengxianDataInit(propData) {
      let series_data = [
        {
          name: "设备设施",
          value: this.judgeNumber(propData.equipmentDangerNum, 5),
        },
        { name: "场所", value: this.judgeNumber(propData.placeDangerNum, 5) },
        {
          name: "作业活动",
          value: this.judgeNumber(propData.taskDangerNum, 5),
        },
      ];
      this.fengxianData_chart = this.$echarts.init(this.$refs.fengxianDataDom);

      let option = {
        tooltip: {
          trigger: "item",
        },
        color: [
          "rgba(141, 78, 218, 1)",
          "rgba(0, 178, 255, 1)",
          "rgba(22, 93, 255, 1)",
        ],
        legend: {
          left: 105,
          top: "center",
          orient: "vertical",
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 15,
          icon: "circle",
          formatter: (val) => {
            let res = series_data.filter((ele) => {
              return ele.name == val;
            });

            let ret_content = `{a|${val}}{b| ${res[0].value}}`;
            return ret_content;
          },
          textStyle: {
            padding: [0, 0, 0, 0],
            rich: {
              a: {
                color: "rgba(78, 89, 105, 1)",
                fontSize: 12,
              },
              b: {
                color: "rgba(78, 89, 105, 1)",
                fontSize: 13,
              },
            },
          },
        },
        series: [
          {
            type: "pie",
            radius: [27, 40],
            top: "center",
            left: "-125",
            top: "center",
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 0,
              borderColor: "#fff",
            },
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: series_data,
          },
        ],
      };

      option && this.fengxianData_chart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-white {
  background-color: #fff;
}

.data-bar {
  padding: 10px 15px;

  .bar-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .bar-item {
      width: 382px;
      height: 153px;
      padding: 13px 20px;
      border-radius: 4px;
      background-size: cover;
      background-repeat: no-repeat;
      margin: 8px 0;
      display: flex;
      justify-content: space-between;

      .item-title {
        width: 120px;

        .number {
          font-size: 24px;
          font-weight: 550;
          color: rgba(54, 59, 67, 1);
        }
        .label {
          font-size: 12px;
          color: rgba(143, 146, 153, 1);
          margin-top: 5px;
        }
      }
      .item-content {
        width: 0;
        flex-grow: 1;

        .item-content-item {
          display: flex;
          justify-content: flex-end;

          .number {
            width: 50px;
            margin-left: 10px;
            font-size: 12px;
            font-weight: 550;
            color: rgba(96, 98, 102, 1);
            text-align: right;
          }
          .label {
            text-align: right;
            font-size: 12px;
            color: rgba(96, 98, 102, 1);
            letter-spacing: 1px;
          }
        }
        .item-content-item:not(:first-child) {
          margin-top: 5px;
        }
      }
    }

    .bar-item:nth-child(1) {
      background-image: url("~@/assets/imgs/home/dataBar_01.png");
    }
    .bar-item:nth-child(2) {
      background-image: url("~@/assets/imgs/home/dataBar_02.png");
    }
    .bar-item:nth-child(3) {
      background-image: url("~@/assets/imgs/home/dataBar_03.png");
    }
    .bar-item:nth-child(4) {
      background-image: url("~@/assets/imgs/home/dataBar_04.png");
    }
  }
}

.data-view {
  margin-top: 18px;
  display: flex;

  .data-view-left {
    padding: 20px;
    width: 0;
    flex-grow: 1;

    .title {
      font-size: 16px;
      font-weight: 550;
      color: rgba(29, 33, 41, 1);
    }
  }
  .data-view-right {
    width: 420px;
    padding: 20px;
    margin-left: 20px;

    .title {
      font-size: 16px;
      font-weight: 550;
      color: rgba(29, 33, 41, 1);
    }
  }
}

.education {
  height: 570px;
}

.certificates-list {
  .certificates-item {
    height: 190px;
  }
  .certificates-item:not(:first-child) {
    border-top: 1px solid rgba(245, 247, 250, 1);
  }
}
.xingshi-card {
  height: 100%;
}
.jiashi-card {
  height: 100%;
}
.zige-card {
  height: 100%;
}

.fengxianData {
  width: 0;
  flex-grow: 1;
  height: 100%;
}
</style>